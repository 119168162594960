<template>
  <!-- 用户管理-->
  <div class="userexamine">
    <div>
      <div>
        <span>关键字</span>
        <el-input v-model="typevalue" placeholder="手机号/姓名/车牌号" style="margin: 0 20px; width: 200px"
          :disabled="disabledtext"></el-input>
        <span style="margin: 0px 20px 0 30px">场地</span>
        <el-select v-model="addressid" filterable placeholder="请输入场地" style="width: 200px" :disabled="!search">
          <el-option :label="item.address_name" :value="item.id" :key="item.id" v-for="item in address_list">
          </el-option>
        </el-select>
        <el-button type="primary" style="margin-left: 10px" @click="searcht()" v-if="search">筛选</el-button>
        <el-button type="warning" style="margin-left: 30px" @click="tuisearch()" v-else>取消搜索</el-button>
        <el-button type="success" style="margin-left: 30px" @click="exportExcel()">导出</el-button>
      </div>
      <el-table @row-click='rowclick' :data="tableData" border style="
          width: 100%;
          margin-top: 30px;
          max-height: 70vh;
          overflow-y: scroll;
        ">
        <el-table-column prop="user_id" label="用户id"></el-table-column>
        <el-table-column prop="created_at" label="时间"></el-table-column>
        <el-table-column label="用户姓名">
          <template #default="scope">
            <span v-if="scope.row.user_name">{{ scope.row.user_name }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="用户头像" width="80px">
          <template #default="scope">
            <img :src="scope.row.head_pic" alt v-if="scope.row.head_pic" />
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="手机号"></el-table-column>
        <el-table-column prop="vehicle_type" label="车辆类型"></el-table-column>
        <el-table-column prop="vehicle_number" label="车牌号"></el-table-column>
        <el-table-column prop="address_name" label="绑定场地"></el-table-column>

        <el-table-column label="免费门禁">
          <template #default="scope">
            <el-switch v-model="scope.row.is_on" :inactive-value="0" :active-value="1" active-color="#13ce66"
              @click.stop @change="switchMf(scope.row)"></el-switch>
          </template>
        </el-table-column>

        <el-table-column label="免费充电">
          <template #default="scope">
            <el-switch v-model="scope.row.is_on2" :inactive-value="0" :active-value="1" active-color="#13ce66"
              @click.stop @change="switchMf2(scope.row)"></el-switch>
          </template>
        </el-table-column>
        
        <el-table-column label="操作" width="200px">
          <template #default="scope">
            <el-button @click.stop="$router.push('/userdetail?id=' + scope.row.user_id)" type="primary" size="small">
              查看用户详细信息</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="详情" v-model="dialogFormVisible" width="30%">
        <el-form :model="form">
          <el-form-item label="姓名" :label-width="formLabelWidth">
            <el-input v-model="form.user_name" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="身份证" :label-width="formLabelWidth">
            <el-input v-model="form.id_card" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="车辆类型" :label-width="formLabelWidth">
            <el-input v-model="form.vehicle_type" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="车牌号" :label-width="formLabelWidth">
            <el-input v-model="form.vehicle_number" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="车辆图片" :label-width="formLabelWidth">
            <img alt v-for="item in form.vehicle_number_image_path" :key="item" style="margin-right: 10px"
              :src="item" />
          </el-form-item>
          <el-form-item label="手机号码" :label-width="formLabelWidth">
            <el-input v-model="form.mobile" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="添加时间" :label-width="formLabelWidth">
            <el-input v-model="form.created_at" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="审核状态" :label-width="formLabelWidth">
            <el-input v-model="form.status" autocomplete="off" disabled></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="
  dialogFormVisible = false;
form = {};
            ">关闭</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

import { vehicleList, vehicleFirst, vehicleEdit, freeChange, changeTypes, expostData, freeChange2 } from "../../request/api"
import { utils, read, writeFileXLSX } from "xlsx";
import { ElMessage } from "element-plus";
export default {
  props: ["page", "pagepage"],
  data() {
    return {
      detailindex: 0,
      addressid: "",
      address_list: [],
      pages: 1,
      currentPage4: 1,
      search: true,
      type: "",
      typevalue: "",
      disabledtext: false,
      tableData: [],
      tableDataCopy: [],
      options: [
        { id: 1, role_name: "用户名称" },
        { id: 2, role_name: "手机号" },
      ],
      zhuantai: [
        { id: 1, role_name: "未商户" },
        { id: 2, role_name: "手机号" },
        { id: 2, role_name: "身份证" },
      ],
      dialogFormVisible: false,
      form: [],
      formLabelWidth: "80px",
      pagetotal: 0,
    };
  },
  computed: {
    ...mapState({
      userType: state => state.userType
    }),
  },
  async created() {
    this.examineList();

  },
  watch: {
    page() {
      console.log(this.page)
      if (this.search) {
        this.examineList();
      } else {
        this.searcht();
      }
    },
    pagepage() {
      if (this.search) {
        this.examineList();
      } else {
        this.searcht();
      }
    },
  },
  methods: {
    // 导出表格
    exportExcel() {
      console.log("导出表格");
      console.log(read, writeFileXLSX)
      if (this.addressid === '') {
        ElMessage.error("请先选择场地");
        return
      }
      expostData({
        address_id: this.addressid
      }).then(res => {
        console.log('用户数据', res)
        /* 需要导出的JSON数据 */
        var data = res.list.user.map(el => {
          return {
            "id": el.id,
            "用户id": el.user_id,
            "用户名": el.user_name,
            "手机号": el.mobile,
            "创建时间": el.created_at
          }
        })
        /* 创建worksheet */
        var ws = utils.json_to_sheet(data);
        /* 新建空workbook，然后加入worksheet */
        var wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "用户表");
        /* 生成xlsx文件 */
        writeFileXLSX(wb, `${this.address_list.find(el => el.id === this.addressid).address_name}.xlsx`);
        ElMessage.success("导出成功");
      })
    },
    changeUserType(row) {
      console.log("切换用户类型", row)
      if (row.id !== undefined) {
        changeTypes({
          user_id: row.user_id,
          type: row.type
        }).then(res => {
          console.log("切换用户类型", res)
          if (res.code == 200) {
            ElMessage.success("切换成功");
          } else {
            ElMessage.error(res.msg);
          }
        })
      }
    },
    switchMf(row) {
      console.log("切换免费开门", row)
      if (row.id !== undefined) {
        freeChange({
          id: row.id,
          is_on: row.is_on
        }).then(res => {
          console.log("免费充电", res)
          if (res.code == 200) {
            ElMessage.success("切换成功");
          } else {
            ElMessage.error(res.msg);
          }
        })
      }
    },
    switchMf2(row) {
      console.log("切换免费充电", row)
      if (row.id !== undefined) {
        freeChange2({
          id: row.id,
          is_on2: row.is_on2
        }).then(res => {
          console.log("免费充电", res)
          if (res.code == 200) {
            ElMessage.success("切换成功");
          } else {
            ElMessage.error(res.msg);
          }
        })
      }
    },
    rowclick(row) {
      this.$router.push('/Userdetail?id=' + row.user_id)
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.pages = val;
      this.handleClickmenu();
    },
    handleClickmenu() {
      if (this.activeName == "second") {
        vehicleFirst({
          user_id: this.user_id,
          type: 1,
          page: this.pages,
          page_size: 10,
        }).then((res) => {
          this.usertaocan = res.list.order_card_list;

          this.usertaocan.forEach((item) => {
            (item.package_type = item.package_type == 2 ? "次卡" : "月卡"),
              (item.parking_type =
                item.parking_type == 0
                  ? "单停"
                  : item.parking_type == 1
                    ? "充停"
                    : "单停");
            item.pay_status =
              item.pay_status == 0
                ? "未支付"
                : item.pay_status == 1
                  ? "已支付"
                  : "退款";
          });
        });
      }
    },
    switchChange(item) {
      let form = {};
      vehicleFirst({ id: item.id }).then((res) => {
        form = res.list.vehicle_list;
        delete form.created_at;
        let aa = form.status;
        delete form.status;
        form.status = aa == 2 ? 1 : 2;
        vehicleEdit(form).then((res) => {
          this.$message({
            type: res.code == 200 ? "success" : "info",
            message: res.msg,
          });
          this.examineList();
        });
      });
    },
    // 审核弹出框
    open(val) {
      let form = {};
      vehicleFirst({ id: val.id }).then((res) => {
        form = res.list.vehicle_list;
        delete form.created_at;
      });
      this.$confirm("是否通过审核", "提示", {
        confirmButtonText: "通过",
        cancelButtonText: "不通过",
        type: "warning",
      })
        .then(() => {
          form.status = 1;
          vehicleEdit(form).then((res) => {
            this.$message({
              type: res.code == 200 ? "success" : "info",
              message: res.msg,
            });
            this.examineList();
          });
        })
        .catch(() => {
          form.status = 2;
          vehicleEdit(form).then((res) => {
            this.$message({
              type: res.code == 200 ? "success" : "info",
              message: res.msg,
            });
            this.examineList();
          });
        });
    },

    searcht() {
      this.disabledtext = true;
      this.search = false;
      this.examineList();
    },
    tuisearch() {
      this.search = true;
      this.disabledtext = false;
      this.type = "";
      this.typevalue = "";
      this.addressid = "";
      this.examineList()

    },
    vehicleText(type) {
      switch (type) {
        case 1:
          return "自行车";
        case 2:
          return "电瓶车";
        case 3:
          return "三轮车";
        case 4:
          return "滑板车";
        case 5:
          return "摩托车";
        default:
          return type;
      }
    },
    examineList() {
      return new Promise((resolve, reject) => {
        vehicleList({
          page: this.page,
          page_size: this.pagepage,
          keyword: this.typevalue,
          address_id: this.addressid,
        }).then((res) => {
          resolve(res.total);
          this.$emit("pagetotalClick", res.total)
          this.tableData = res.list.vehicle_list;
          this.address_list = res.list.address_list;
          this.zhuantai = res.list.vehicle_status;
          this.tableData = res.list.vehicle_list.filter((item) => {
            return item.status == 1 || item.status == 2;
          });
          this.tableData.forEach((item) => {
            item.status = item.status == 1 ? true : false;
          });
          this.tableData.forEach((item) => {
            item.vehicle_type = this.vehicleText(item.vehicle_type)
            item.status = item.status == 1 ? true : false;
          });
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.userexamine {
  /deep/.cell {
    text-align: center;
  }

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}
</style>